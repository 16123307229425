import { ArticleEndPoint } from '@/Models/ArticleEndPoint'
import store from '.'
import { Module, Mutation, VuexModule, Action } from 'vuex-class-modules'
import { StoreNames } from '@/config'
import { Article } from '@/Models/Article'

@Module()
class ArticleModule extends VuexModule {
  private _article: Article | null = localStorage.getItem(StoreNames.ARTICLE)
    ? (JSON.parse(localStorage.getItem(StoreNames.ARTICLE) || '{}') as Article)
    : null

  private _article_endpoint: ArticleEndPoint | null = localStorage.getItem(
    StoreNames.ARTICLEENDPOINT,
  )
    ? (JSON.parse(
        localStorage.getItem(StoreNames.ARTICLEENDPOINT) || '{}',
      ) as ArticleEndPoint)
    : null

  private _quadrant_content_id: number | null = localStorage.getItem(
    StoreNames.QUADRANT_CONTENT_ID,
  )
    ? JSON.parse(localStorage.getItem(StoreNames.QUADRANT_CONTENT_ID) ?? '-1')
    : null

  get quadrant_content_id(): number {
    return Number(this._quadrant_content_id)
  }

  get article(): Article | null {
    return this._article
  }

  get article_endpoint(): ArticleEndPoint | null {
    return this._article_endpoint
  }

  @Mutation
  setQuadrantContentId(payload: number) {
    this._quadrant_content_id = payload
    localStorage.setItem(
      StoreNames.QUADRANT_CONTENT_ID,
      JSON.stringify(payload),
    )
  }

  @Mutation
  setArticle(payload: Article) {
    this._article = payload
    localStorage.setItem(StoreNames.ARTICLE, JSON.stringify(this._article))
  }

  @Mutation
  setArticleEndPoint(payload: ArticleEndPoint | null) {
    this._article_endpoint = payload
    if (payload)
      localStorage.setItem(
        StoreNames.ARTICLEENDPOINT,
        JSON.stringify(this._article_endpoint),
      )
    else localStorage.removeItem(StoreNames.ARTICLEENDPOINT)
  }
}
const ModuleArticle = new ArticleModule({ store, name: 'articleModule' })
export default ModuleArticle
