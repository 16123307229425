import http from '@/services'
import * as toast from '@/services/toast'
import { Collection } from '@/Models/Collection'
import {
  QuadrantsRelationship,
  Matrix,
  MatrixRelationships,
  TypeRelationships,
  Quadrant,
} from '@/Models'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CorrelationComponent from '@/components/Correlation/CorrelationComponent.vue'
import SubNav from '@/components/Nav/SubNav.vue'
import ModalComponent from '@/components/ModalComponent/ModalComponent.vue'
import DescriptionCard from '@/components/DescriptionCard/index.vue'
import ExpandableButton from '@/components/ExpandableButton/index.vue'
//import collectionModule from '@/store/collection'
import { httpMulti } from '@/services/http'
import ModuleArticle from '@/store/moduleArticle'

@Component({
  components: {
    CorrelationComponent,
    SubNav,
    ModalComponent,
    DescriptionCard,
    ExpandableButton,
    ValidationObserver,
    ValidationProvider,
    ModalDelete,
  },
})
export default class MatrixRelationship extends Vue {
  showmodal = false
  matrixContent = []
  collection_array: Collection[] = []
  matrixContentSelected = ''
  collection_selected = 0
  matrix_selected: Matrix | null = null
  quadrant_selected: Quadrant = new Quadrant()
  collection_id = 0
  keySelected = ''
  id = ModuleArticle.quadrant_content_id
  quadrantRelationship: MatrixRelationships = new MatrixRelationships()
  matrix_array: Matrix[] = []
  relationship_id = 0
  priority_id = 0
  delete_modal_active = false
  //dataTypeRelationship: Array<MatrixRelationships> = []
  matrixRelationship: MatrixRelationships[] = []
  typeRelationship: TypeRelationships = new TypeRelationships()
  //priorityRelationships_array: MatrixRelationship[] = []
  data: QuadrantsRelationship[] = []
  dataRelationships: Array<QuadrantsRelationship> = []
  async mounted() {
    await this.getColecctions()
    await this.getRelationshipData()
    await this.getTypeRelationship()
    await this.getPriorityRelationships()
  }

  showModal() {
    this.showmodal = true
  }
  closeModal() {
    this.showmodal = false
    this.delete_modal_active = false
    this.collection_selected = 0
    this.matrix_selected = null
    //this.quadrant_selected = null
    this.matrixContentSelected = ''
    this.relationship_id = 0
    this.priority_id = 0
  }
  async getRelationshipData() {
    try {
      const res = await http.get(`hoshin/quadrants_relationship/${this.id}/`)
      if (!res.data) throw new Error(res.data)
      this.dataRelationships = res.data
      console.log(this.dataRelationships, 'HERE')
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }
  GDev() {
    let cuadrant: any

    if (
      this.matrix_selected?.north_quadrant.name === this.quadrant_selected.name
    )
      cuadrant = this.matrix_selected?.north_quadrant
    if (
      this.matrix_selected?.west_quadrant.name === this.quadrant_selected.name
    )
      cuadrant = this.matrix_selected?.west_quadrant
    if (
      this.matrix_selected?.south_quadrant.name === this.quadrant_selected.name
    )
      cuadrant = this.matrix_selected?.south_quadrant
    if (
      this.matrix_selected?.east_quadrant.name === this.quadrant_selected.name
    )
      cuadrant = this.matrix_selected?.east_quadrant

    console.log(cuadrant)
    this.matrixContent = cuadrant.content
  }
  async getTypeRelationship() {
    try {
      const res = await http.get(`hoshin/type_relationship/`)
      this.typeRelationship = res.data.map((x: TypeRelationships) => ({
        ...x,
        id: x.id,
      }))
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
  }
  confirmation() {
    const relationship = {
      quadrant_1_id: this.id,
      quadrant_2_id: this.quadrant_selected.id,
      type_id: this.relationship_id,
      priority_id: this.priority_id,
    }
    this.createRelationship(relationship)
  }

  async createRelationship(quadrantRelationship: any) {
    try {
      await http.post('hoshin/quadrants_relationship/', quadrantRelationship)
      this.$buefy.toast.open({
        message: 'Relacion agregada',
        type: 'is-success',
      })
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
    this.closeModal()
    this.getRelationshipData()
  }
  showActiveDeleteModal(quadrantRelationship: MatrixRelationships) {
    this.quadrantRelationship = quadrantRelationship
    this.delete_modal_active = !this.delete_modal_active
  }

  async deleteRelationship() {
    try {
      await http.delete(
        `hoshin/quadrants_relationship/${this.quadrantRelationship}/`,
      )

      this.$buefy.toast.open({
        message: 'Eliminado con exito',
        type: 'is-success',
      })
    } catch (error) {
      console.log(error)
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
    this.closeModal()
    this.getRelationshipData()
  }

  async getPriorityRelationships() {
    try {
      const res = await http.get(`hoshin/priority_relationship/`)
      this.matrixRelationship = res.data.map((x: TypeRelationships) => ({
        ...x,
      }))
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
  }

  async getMatrices() {
    try {
      const res = await http.get(
        `hoshin/collection_matrices/${this.collection_selected}/`,
      )
      //this.matrix_array = res.data
      this.matrix_array = res.data.map((x: Matrix) => ({
        ...x,
      }))
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
  }

  async getColecctions() {
    try {
      const res = await httpMulti.get('hoshin/collection/')
      this.collection_array = res.data.map((collection: any) => ({
        ...collection,
        id: collection.id,
      }))
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
  }
}
